import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "../services/axios.custom";
import { NAMED_COLORS } from "../data_utils/colors";
import ColorfulBannerCard from "../components/utils/ColorfulBannerCard";
import TwoLineCharts from "../components/charts/TwoLineCharts";
import StaticGraph from "../components/utils/StaticGraph";
import GenericTable from "../components/utils/GenericTable";
import { englishNumberToPersian } from "../data_utils/language_converter";
import AuthorStatsCard from "../components/single-author-view/AuthorStatsCard";
import ApexLineChart from "../components/charts/ApexLineChart";
import MateriaTable from "../components/utils/MaterialTable";
import WhiteCard from "../components/utils/WhiteCard";

const SingleInstitutionView = () => {
  const params = useParams();
  const [institution, setInstitution] = useState({});
  // console.log(params);
  useEffect(() => {
    const fetchInstitution = async () => {
      try {
        const { data } = await Axios.get(`/api/institutions/${params.id}`);
        setInstitution(data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchInstitution();
  }, []);
  const renderStats = () => {
    if (Object.keys(institution).length === 0) return <></>;
    const stats = [
      {
        display_name: "تعداد پژوهش ها",
        value: institution.works_count,
        color: NAMED_COLORS.LIGHT_PEACH,
      },
      {
        display_name: "تعداد ارجاعات",
        value: institution.cited_by_count,
        color: NAMED_COLORS.LIGHT_BLUE,
      },
      {
        display_name: "کشور",
        value: institution.country_code,
        color: NAMED_COLORS.LIGHT_GREEN,
      },
      {
        display_name: "H Index",
        value: institution.summary_stats.h_index,
        color: NAMED_COLORS.LIGHT_LAVENDER,
      },
      {
        display_name: "I10 Index",
        value: institution.summary_stats.i10_index,
        color: NAMED_COLORS.LIGHT_YELLOW,
      },
    ];
    stats.forEach((stat) => {
      stat.value = englishNumberToPersian(stat.value);
    });
    return <AuthorStatsCard stats={stats} />;
  };

  const renderInstitution = () => {
    if (Object.keys(institution).length === 0) return <></>;
    const stats = [
      {
        display_name: "تعداد پژوهش ها",
        value: institution.works_count,
        color: NAMED_COLORS.LIGHT_PEACH,
      },
      {
        display_name: "تعداد ارجاعات",
        value: institution.cited_by_count,
        color: NAMED_COLORS.LIGHT_BLUE,
      },
      {
        display_name: "کشور",
        value: institution.country_code,
        color: NAMED_COLORS.LIGHT_GREEN,
      },
      {
        display_name: "H Index",
        value: institution.summary_stats.h_index,
        color: NAMED_COLORS.LIGHT_LAVENDER,
      },
      {
        display_name: "I10 Index",
        value: institution.summary_stats.i10_index,
        color: NAMED_COLORS.LIGHT_YELLOW,
      },
    ];
    const chartData = institution.counts_by_year;
    chartData.sort((a, b) => (a.year > b.year ? 1 : -1));
    let xData = chartData.map((x) => x.year);
    let worksCount = chartData.map((x) => x.works_count);
    let citations = chartData.map((x) => x.cited_by_count);
    return (
      <>
        <div className="white_card m-2 p-3">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1 className="text-title">{institution.display_name}</h1>
              <h2 className="text-subtitle">
                ({institution.international.display_name["fa"]})
              </h2>
            </div>
            <div className="rounded circle fluid">
              <div
                className="rounded"
                style={{
                  backgroundImage: `url(${institution.image_thumbnail_url})`,
                  minHeight: "100px",
                  minWidth: "100px",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="d-flex mt-2">
          <div className="d-flex flex-column flex-grow-1">
            <div className="white_card rounded-bg flex-grow-1 me-2 ">
              <div className="white_card_header rtl">
                <div className="main-title">
                  <h3 className="m-0">تعداد پژوهش ها در طول زمان</h3>
                  <span>از سال ۲۰۱۲ الی ۲۰۲۴</span>
                </div>
              </div>
              <div className="author-charts" style={{ height: "200px" }}>
                <ApexLineChart
                  xData={xData}
                  yData={worksCount}
                  name={"تعداد پژوهش ها"}
                />
              </div>
            </div>

            <div className="white_card rounded-bg flex-grow-1 me-2 mt-2">
              <div className="white_card_header rtl">
                <div className="main-title">
                  <h3 className="m-0">تعداد ارجاعات در طول زمان</h3>
                  <span>از سال ۲۰۱۲ الی ۲۰۲۴</span>
                </div>
              </div>
              <div className="author-charts" style={{ height: "200px" }}>
                <ApexLineChart
                  xData={xData}
                  yData={citations}
                  name={"تعداد ارجاعات"}
                  color={"orangered"}
                />
              </div>
            </div>
          </div>
          <div style={{ minWidth: "450px" }}>{renderStats()}</div>
        </div>
        <div className="m-4">
          <WhiteCard
            title="پژوهشگران این موسسه تحقیقاتی"
            subtitle="مرتب شده بر اساس تعداد ارجاعات"
          >
            <MateriaTable
              fields={[
                "display_name",
                "cited_by_count",
                "works_count",
                "summary_stats.i10_index",
              ]}
              fetch_url={`/api/authors?filter=last_known_institution.id:${
                institution.id.split("lex.org/")[1]
              }`}
              redirect_url={"/authors"}
              titles={["نام", "تعداد ارجاعات", "تعداد پژوهش ها", "ایندکس i10"]}
            />
          </WhiteCard>
        </div>
        <div className="white_card rounded-bg p-3 m-2">
          <div className="white_card_header rtl">
            <div className="main-title">
              <h3 className="m-0">گراف ارتباطی</h3>
              <span>رابطه میان تمامی مفاهیم تحقیق شده</span>
            </div>
          </div>
          <StaticGraph concepts={institution.x_concepts} />
        </div>
      </>
    );
  };
  return <div className="container-fluid mt-2">{renderInstitution()}</div>;
};

export default SingleInstitutionView;
