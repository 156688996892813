import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NAMED_COLORS } from "../data_utils/colors";
import TwoLineCharts from "../components/charts/TwoLineCharts";
import { englishNumberToPersian } from "../data_utils/language_converter";
import Axios, { REACT_APP_BACKEND_URL } from "../services/axios.custom";
import { convertTreeTod3GraphData } from "../data_utils/concept_tree_utils";
import ConceptMap from "../components/ConceptGraph";
import { Graph } from "react-d3-graph";
import "./SingleAuthorView.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import GenericTable from "../components/utils/GenericTable";
import LoadingIndicator from "../components/utils/LoadingIndicator";
import { setSingleAuthor } from "../store/actions/author_actions";
import AuthorStatsCard from "../components/single-author-view/AuthorStatsCard";
import ApexLineChart from "../components/charts/ApexLineChart";
import MateriaTable from "../components/utils/MaterialTable";
import WhiteCard from "../components/utils/WhiteCard";
import AuthorSankey from "../components/authors/AuthorHeatMap";
import CoAuthors from "../components/authors/CoAuthors";
import AuthorHeatMap from "../components/authors/AuthorHeatMap";

const config = {
  directed: true,
  // automaticRearrangeAfterDropNode: true,
  collapsible: true,
  // height: window.innerHeight,
  height: 500,
  highlightDegree: 2,
  highlightOpacity: 0.2,
  linkHighlightBehavior: true,
  maxZoom: 12,
  minZoom: 0.05,
  nodeHighlightBehavior: true, // comment this to reset nodes positions to work
  panAndZoom: false,
  staticGraph: false,
  // width: window.innerWidth,
  // width:
  d3: {
    alphaTarget: 0.05,
    gravity: -250,
    linkLength: 120,
    linkStrength: 2,
  },
  node: {
    color: "#d3d3d3",
    fontColor: "black",
    fontSize: 10,
    fontWeight: "normal",
    highlightColor: "red",
    highlightFontSize: 14,
    highlightFontWeight: "bold",
    highlightStrokeColor: "red",
    highlightStrokeWidth: 1.5,
    labelProperty: (n) => n.name, //(n.name ? `${n.id} - ${n.name}` : n.id),
    mouseCursor: "crosshair",
    opacity: 0.9,
    renderLabel: true,
    size: 200,
    strokeColor: "none",
    strokeWidth: 1.5,
    svg: "",
    symbolType: "circle",
    viewGenerator: null,
  },
  link: {
    color: "lightgray",
    highlightColor: "red",
    mouseCursor: "pointer",
    opacity: 1,
    semanticStrokeWidth: true,
    strokeWidth: 3,
    type: "STRAIGHT",
  },
};

const SingleAuthorView = () => {
  const ref = React.useRef(null);
  // const { author } = useSelector((store) => store.author);
  const [author, setAuthor] = useState({});
  const [conceptGraph, setConceptGraph] = useState({ nodes: [], links: [] });
  const [updateKey, setUpdateKey] = useState(0);
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchAuthor = async () => {
      try {
        const { data } = await Axios.get(`/api/authors/${params.id}`);
        // dispatch(setSingleAuthor(data));
        setAuthor(data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchAuthor();
  }, [params.id]);

  useEffect(() => {
    setUpdateKey((k) => ++k);
    // ref.current.resetNodesPositions();
  }, [conceptGraph.nodes, params.id]);
  useEffect(() => {
    const filterConceptGraph = async (filters) => {
      try {
        const { data } = await Axios.post(`/filter_graph`, {
          filters,
        });
        const graph = convertTreeTod3GraphData(data.data, 100, 10000);
        graph.nodes[0].color = "black";
        setConceptGraph(graph);
      } catch (e) {
        console.error(e);
      }
    };
    if (!Object.keys(author).length) {
      return;
    }
    const concepts = author.x_concepts;
    let filters = [];
    for (let concept of concepts) {
      filters.push([concept.display_name, concept.score]);
    }
    filterConceptGraph(filters);
  }, [author, params.id]);

  // if (!Object.keys(author).length) {
  //     return <></>;
  // }
  // useEffect(() => {
  //     if (ref.current) {
  //         // setTimeout(() => {
  //         ref.current.resetNodesPositions();
  //         // }, 1000)
  //     }
  // }, [ref, conceptGraph.nodes.length]);
  const renderStats = () => {
    const stats = [
      {
        name: "h_index",
        display_name: "H Index",
        value: author.summary_stats.h_index,
        color: NAMED_COLORS.LIGHT_PINK,
      },
      {
        name: "i10_index",
        display_name: "I10 Index",
        value: author.summary_stats.i10_index,
        color: NAMED_COLORS.LIGHT_GREEN,
      },
      {
        name: "total_citations",
        display_name: "کل ارجاعات",
        value: author.cited_by_count,
        color: NAMED_COLORS.LIGHT_BLUE,
      },
      {
        name: "Count of Works",
        display_name: "تعداد مقالات",
        value: author.works_count,
        color: NAMED_COLORS.LIGHT_PURPLE,
      },
    ];
    stats.forEach((stat) => {
      stat.value = englishNumberToPersian(stat.value);
    });
    return <AuthorStatsCard stats={stats} />;
    return stats.map(renderCard);
  };

  const renderCard = (stat, idx) => {
    // return ()
    return (
      <div
        style={{ backgroundColor: stat.color, minWidth: "200px" }}
        className="p-3 border rounded shadow-sm text-center"
      >
        <span>{stat.display_name}</span>
        <hr />

        <span>{englishNumberToPersian(stat.value)}</span>
      </div>
    );
  };
  let chartData = [];
  if (author.counts_by_year) {
    chartData = author.counts_by_year;
    chartData.sort((a, b) => (a.year > b.year ? 1 : -1));
  }
  let xData = chartData.map((x) => x.year);
  let worksCount = chartData.map((x) => x.works_count);
  let citations = chartData.map((x) => x.cited_by_count);
  // console.log({ xData, chartData });
  // console.log({ chartData });

  return (
    <div className="container-fluid my-2" key={params.id}>
      <div className="row">
        <div className="col-12">
          {/* <div className="card shadow p-3"> */}
          {/* <div className="card-body"> */}
          {Object.keys(author).length ? (
            <>
              <div className="white_card rounded-bg">
                <div className="white_card_header">
                  <div className="author-info">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="main-title">
                        <h3 className="m-0">{author.display_name}</h3>
                        <span>
                          {author.last_known_institution && (
                            <NavLink
                              className="interlink"
                              to={`/institutions/${
                                author.last_known_institution.id.split(
                                  "lex.org/"
                                )[1]
                              }`}
                            >
                              <h2 className="text-subtitle">
                                {author.last_known_institution.display_name}
                              </h2>
                            </NavLink>
                          )}
                        </span>
                      </div>
                      <h1 className="text-title"></h1>
                      {author.last_known_institution && (
                        <img
                          className="inline-flag"
                          src={`${REACT_APP_BACKEND_URL}/static/flags/${author.last_known_institution.country_code}.svg`}
                          alt="country flag"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-2">
                {/* <div className="card mt-3">
                  <div className="card-body">
                    <div className="author-stats d-flex justify-content-between my-4"> */}
                {/* {renderStats()} */}

                {/* </div>
                  </div>
                </div> */}
                <div className="d-flex flex-column flex-grow-1">
                  <div className="white_card rounded-bg flex-grow-1 me-2 ">
                    <div className="white_card_header rtl">
                      <div className="main-title">
                        <h3 className="m-0">تعداد پژوهش ها در طول زمان</h3>
                        <span>از سال ۲۰۱۲ الی ۲۰۲۴</span>
                      </div>
                    </div>
                    <div className="author-charts" style={{ height: "200px" }}>
                      {/* <TwoLineCharts
                        data={chartData}
                        xlabel="year"
                        ylabel1="cited_by_count"
                        ylabel2="works_count"
                      /> */}
                      <ApexLineChart
                        xData={xData}
                        yData={worksCount}
                        name={"تعداد پژوهش ها"}
                      />
                    </div>
                  </div>

                  <div className="white_card rounded-bg flex-grow-1 me-2 mt-2">
                    <div className="white_card_header rtl">
                      <div className="main-title">
                        <h3 className="m-0">تعداد ارجاعات در طول زمان</h3>
                        <span>از سال ۲۰۱۲ الی ۲۰۲۴</span>
                      </div>
                    </div>
                    <div className="author-charts" style={{ height: "200px" }}>
                      <ApexLineChart
                        xData={xData}
                        yData={citations}
                        name={"تعداد ارجاعات"}
                        color={"orangered"}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ minWidth: "450px" }}>{renderStats()}</div>
              </div>
              <div className="row">
                <div className="col-8">
                  <WhiteCard
                    title={"نمودار نقشه گرمای نویسنده"}
                    subtitle="حوزه فعالیت نویسنده در طول سالیان"
                  >
                    <AuthorHeatMap authorId={params.id} />
                  </WhiteCard>
                </div>
                <div className="col-4">
                  <CoAuthors authorId={params.id} />
                </div>
              </div>

              <div className="white_card my-3">
                <div className="white_card_header rtl">
                  <div className="main-title">
                    <h3 className="m-0">فهرست پژوهش ها</h3>
                    <span>مرتب شده بر اساس تعداد ارجاعات</span>
                  </div>
                </div>
                <div className="white_card_body">
                  <MateriaTable
                    fields={[
                      "display_name",
                      "cited_by_count",
                      "publication_year",
                      "referenced_works_count",
                    ]}
                    fetch_url={`/api/works?filter=author.id:${author.id}`}
                    redirect_url={"/works"}
                    titles={[
                      "عنوان اثر",
                      "تعداد ارجاعات",
                      "تاریخ انتشار",
                      "تعداد ارجاعات درون اثر",
                    ]}
                  />
                </div>
              </div>
              {/* <div className="author-works mt-3">
                <GenericTable
                  fields={[
                    "display_name",
                    "cited_by_count",
                    "created_date",
                    "referenced_works_count",
                  ]}
                  fetch_url={`/api/works?filter=author.id:${author.id}`}
                  redirect_url={"/works"}
                  titles={[
                    "Name",
                    "Citations",
                    "Created Date",
                    "Referenced Work Count",
                  ]}
                />
              </div> */}
              <div className="white_card rounded-bg p-3 m-2">
                <div className="white_card_header rtl">
                  <div className="main-title">
                    <h3 className="m-0">گراف ارتباطی</h3>
                    <span>رابطه میان تمامی مفاهیم تحقیق شده</span>
                  </div>
                </div>
                <div key={updateKey} className="author-concepts">
                  <Graph
                    key={updateKey}
                    config={config}
                    id="author-graph"
                    ref={ref}
                    data={conceptGraph}
                  />
                </div>
              </div>
            </>
          ) : (
            <LoadingIndicator />
          )}
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default SingleAuthorView;
